<template>
    <b-modal size="lg" :title="$t('general.modificator')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                        <b-form-input autofocus v-model="addObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('keys.priority')">
                    <validation-provider #default="{ errors }" :name="$t('keys.priority')" rules="required|min_value:0">
                        <b-form-input class="col-2" type="number" v-model.number="addObject.priority"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('general.discount')">
                    <validation-provider #default="{ errors }" :name="$t('general.discount')" rules="required|min_value:0|max_value:100">
                        <b-input-group append="%">
                            <b-form-input type="number" v-model.number="addObject.discount"/>
                        </b-input-group>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('general.active')" >
                    <validation-provider #default="{ errors }" :name="$t('general.active')">
                        <b-form-radio-group v-model="addObject.active" :options="activeOptions" name="active"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group  :label="$t('keys.valid_from')">
                    <validation-provider #default="{ errors }" :name="$t('keys.valid_from')" rules="required">
                        <date-picker :first-day-of-week="2" v-model="addObject.valid_conditions.valid_from" :max-date="addObject.valid_conditions.valid_to" :masks="masks" :model-config="modelConfig" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="inputValue"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('keys.valid_to')">
                    <validation-provider #default="{ errors }" :name="$t('keys.valid_to')" rules="required">
                        <date-picker :first-day-of-week="2" :min-date="addObject.valid_conditions.valid_from" v-model="addObject.valid_conditions.valid_to" :masks="masks" :model-config="modelConfig" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="inputValue"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" :name="$t('general.start_time')" rules="required">
                        <b-form-radio-group :hoursFormat="24" v-model="selectTime" :options="optionsTime" name="select time"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="selectTime === 1" :label="$t('general.start_time')">
                    <validation-provider #default="{ errors }" :name="$t('general.start_time')" :rules="getTimeRuleBefore">
                        <date-picker :first-day-of-week="2" @input="formatTimeStart" class="timePickerNoDate" mode="time" disabled-dates is24hr timezone="UTC" v-model="time.start" :mask="{
                            title: 'HH MM'
                        }"
                        >
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="inputValue"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="selectTime === 1" :label="$t('keys.end_time')">
                    <validation-provider #default="{ errors }" :name="$t('keys.end_time')" :rules="getTimeRuleAfter">
                        <date-picker :first-day-of-week="2" @input="formatTimeEnd" class="timePickerNoDate" mode="time" disabled-dates is24hr timezone="UTC" v-model="time.end" :mask="{
                            title: 'HH MM'
                        }"
                        >
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="inputValue"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <h4 class="mt-2">{{$t('keys.valid_on')}}</h4>
                <b-form-group style="position: relative" :label="$t('keys.repeat')">
                    <validation-provider #default="{ errors }" :name="$t('keys.repeat')" rules="required">
                        <v-select v-model="addObject.valid_conditions.interval" appendToBody label="text" :options="repeatInterval" :reduce="x => x.value" :clearable="false"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="addObject.valid_conditions.interval === 'monthly'" :label="$t('keys.weekdays')">
                    <validation-provider #default="{ errors }" :name="$t('keys.weekdays')" rules="required">
                        <v-select v-model="addObject.valid_conditions.days" appendToBody label="text" :options="daysOptions" :reduce="x => x.value" :multiple="true" :clearable="false"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="addObject.valid_conditions.interval === 'weekly'" class="mt-2">
                    <validation-provider #default="{ errors }" :name="$t('keys.week')" rules="required">
                        <div class="d-flex flex-column flex-md-row align-items-md-center">
                            <h5 class="mb-0">{{$t('keys.repeat')}}</h5>
                            <div class="d-flex align-items-center flex-wrap mt-1 mt-md-0">
                                <label class="day" v-for="(item, index) of weekdays" :key="'day_'+index" :class="[addObject.valid_conditions.weekdays.includes(item.value) ? 'active': '']">
                                    <input type="checkbox" name="week" v-model="addObject.valid_conditions.weekdays" :value="item.value" style="display: none"/>
                                    {{item.text}}
                                </label>
                            </div>
                        </div>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group>
                    <validation-provider #default="{ errors }" :name="$t('keys.color')" rules="required">
                        <color-selector  v-model="addObject.color" :product-name="addObject.name"></color-selector>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormRadioGroup, BInputGroup} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required} from '@validations'
    import vSelect from 'vue-select'
    import {ModificatorError} from '@/libs/enums/Modificator'
    import * as Sentry from '@sentry/vue'
    import {DatePicker} from 'v-calendar'
    import ColorSelector from '@/views/ColorSelector/ColorSelector.vue'
    import {getOptionsTime, getRepeatInterval, getWeekdays, getDaysOptions, getActiveOptions} from '@/libs/constants/price-lists.js'


    export default {
        components: {
            ColorSelector,
            BFormGroup,
            DatePicker,
            BFormRadioGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            BInputGroup
        },
        props: {
            businessUnitId: {
                type: String
            },
            mod: Object
        },
        data() {
            return {
                selectTime: 0,
                modalActive: false,
                masks: {
                    input: 'WWWW, D. MMMM YYYY'
                },
                modelConfig: {
                    type: 'string',
                    mask: 'iso',
                    timeAdjust: '23:59:59'
                },
                time: {
                    start: this.dayjs('1970-01-01T00:00:00Z').toDate(),
                    end: this.dayjs('1970-01-01T00:00:00Z').toDate()
                },
                addObject: {
                    name: '',
                    priority: 0,
                    discount: null,
                    products_ids: [],
                    categories_ids: [],
                    order_options_ids: [],
                    color: '#7367f0',
                    order_options_categories_ids: [],
                    active: false,
                    business_unit_id: '',
                    valid_conditions: {
                        interval: '',
                        valid_from: '',
                        valid_to: '',
                        days: [],
                        weekdays: [],
                        start_hour: '00:00:00',
                        end_hour: '23:59:59'
                    }
                },
                required,
                checkOptions: [],
                editing: false,
                products: [],
                categories: [],
                order_options: [],
                order_options_categories: []
            }
        },
        methods: {
            formatTimeStart() {
                this.addObject.valid_conditions.start_hour = this.dayjs(this.time.start).utc().format('HH:mm:ss')
            },
            formatTimeEnd() {
                this.addObject.valid_conditions.end_hour = this.dayjs(this.time.end).utc().format('HH:mm:ss')
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    this.addObject.discount = this.addObject.discount / 100
                    this.editing = true
                    const startTime = this.addObject.valid_conditions.start_hour.substring(11, 19)
                    const endTime = this.addObject.valid_conditions.end_hour.substring(11, 19)
                    this.addObject.business_unit_id = this.businessUnitId
                    if (startTime === '00:00:00') {
                        if (endTime === '23:59:59') {
                            this.selectTime = 0
                        } else this.selectTime = 1
                    } else {
                        this.selectTime = 1
                    }

                    this.time.start = this.dayjs(`1970-01-01T${startTime}Z`).toDate()
                    this.time.end = this.dayjs(`1970-01-01T${endTime}Z`).toDate()

                    this.formatTimeStart()
                    this.formatTimeEnd()
                } else {
                    this.editing = false

                    this.addObject = {
                        name: '',
                        priority: 0,
                        discount: null,
                        products_ids: [],
                        categories_ids: [],
                        order_options_ids: [],
                        color: '#7367f0',
                        order_options_categories_ids: [],
                        active: false,
                        business_unit_id: '',
                        valid_conditions: {
                            interval: '',
                            valid_from: '',
                            valid_to: '',
                            days: [],
                            weekdays: [],
                            start_hour: '00:00:00',
                            end_hour: '23:59:59'
                        }
                    }

                    this.time = {
                        start: this.dayjs('1970-01-01T00:00:00Z').toDate(),
                        end: this.dayjs('1970-01-01T00:00:00Z').toDate()
                    }

                    this.selectTime = 0
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    const payload = {
                        name: this.addObject.name,
                        priority: Number(this.addObject.priority),
                        discount: (Number(this.addObject.discount) * 100),
                        valid_conditions: {
                            interval: this.addObject.valid_conditions.interval,
                            valid_from: this.addObject.valid_conditions.valid_from,
                            valid_to: this.addObject.valid_conditions.valid_to,
                            start_hour: `1970-01-01T${this.addObject.valid_conditions.start_hour}Z`,
                            end_hour: `1970-01-01T${this.addObject.valid_conditions.end_hour}Z`,
                            days: this.addObject.valid_conditions.days.sort(),
                            weekdays: this.addObject.valid_conditions.weekdays.sort(),
                            is_whole_day: !this.selectTime
                        },
                        business_unit_id: this.businessUnitId,
                        color: this.addObject.color,
                        active: this.addObject.active
                    }

                    const resp = await this.$http.post('/api/client/v1/modificators/', payload)
                    this.modalActive = false
                    this.$emit('itemAdded', resp.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        priority: 0,
                        discount: null,
                        products_ids: [],
                        categories_ids: [],
                        order_options_ids: [],
                        color: '#7367f0',
                        order_options_categories_ids: [],
                        active: false,
                        business_unit_id: '',
                        valid_conditions: {
                            interval: '',
                            valid_from: '',
                            valid_to: '',
                            days: [],
                            weekdays: [],
                            start_hour: '00:00:00',
                            end_hour: '23:59:59'
                        }
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.displayError(err.response.data.error)
                }
            },
            async edit() {
                try {
                    const thisIns = this

                    const payload = JSON.parse(JSON.stringify(this.addObject))
                    payload.discount = this.addObject.discount * 100

                    payload.valid_conditions.valid_from = this.addObject.valid_conditions.valid_from
                    payload.valid_conditions.valid_to = this.addObject.valid_conditions.valid_to
                    payload.valid_conditions.start_hour = `1970-01-01T${this.addObject.valid_conditions.start_hour}Z`
                    payload.valid_conditions.end_hour = `1970-01-01T${this.addObject.valid_conditions.end_hour}Z`
                    payload.valid_conditions.days = this.addObject.valid_conditions.days.sort(function(a, b) {
                        return a - b
                    })
                    payload.valid_conditions.weekdays = this.addObject.valid_conditions.weekdays.sort(function(a, b) {
                        return a - b
                    })
                    payload.business_unit_id = this.businessUnitId

                    // payload.discount = Number(this.addObject.discount)
                    // payload.priority = Number(this.addObject.priority)

                    await thisIns.$http.post(`/api/client/v1/modificators/${this.addObject.id}`, payload)
                    thisIns.modalActive = false
                    thisIns.$emit('edit')
                    thisIns.$emit('itemAdded')
                    thisIns.$printSuccess(thisIns.$t('general.edit_success'))
                    thisIns.addObject = {
                        name: '',
                        priority: 0,
                        discount: null,
                        products_ids: [],
                        categories_ids: [],
                        order_options_ids: [],
                        color: '#7367f0',
                        order_options_categories_ids: [],
                        active: false,
                        business_unit_id: '',
                        valid_conditions: {
                            interval: '',
                            valid_from: '',
                            valid_to: '',
                            days: [],
                            weekdays: [],
                            start_hour: '00:00:00',
                            end_hour: '23:59:59'
                        }
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.displayError(err.response.data.error)
                }
            },
            displayError(error) {
                switch (error) {
                case ModificatorError.PriorityError:
                    this.$printWarning(this.$t('print.warning.priority_modificator'))
                    break
                case ModificatorError.DateToIsPastTime:
                    this.$printWarning(this.$t('print.warning.date_to_is_past'))
                    break
                case ModificatorError.DateToAfterDateFrom:
                    this.$printWarning(this.$t('print.warning.date_to_after_from'))
                    break
                case ModificatorError.IntervalCheckError:
                    this.$printWarning(this.$t('print.warning.interval_check'))
                    break
                case ModificatorError.IntervalMonthlyError:
                    this.$printWarning(this.$t('print.warning.interval_monthly'))
                    break
                case ModificatorError.IntervalWeeklyError:
                    this.$printWarning(this.$t('print.warning.interval_weekly'))
                    break
                case ModificatorError.StartTimeAfterEndTime:
                    this.$printWarning(this.$t('print.warning.start_time_after_end_time'))
                    break
                default:
                    this.$printError(this.$t('print.error.on_add'))

                }
            }
        },
        computed: {
            optionsTime() {
                return getOptionsTime()
            },
            repeatInterval() {
                return getRepeatInterval()
            },
            weekdays() {
                return getWeekdays()
            },
            daysOptions() {
                return getDaysOptions()
            },
            activeOptions() {
                return getActiveOptions()
            },
            getTimeRuleBefore() {
                return `required|time_before:${this.addObject.valid_conditions.end_hour}`
            },
            getTimeRuleAfter() {
                return `required|time_after:${this.addObject.valid_conditions.start_hour}`
            }
        },
        watch: {
            'selectTime'(val) {
                if (val === 0) {
                    this.addObject.valid_conditions.start_hour = '00:00:00'
                    this.addObject.valid_conditions.end_hour = '23:59:59'
                }
            },
            'addObject.valid_conditions.interval'(val) {
                if (val === this.repeatInterval[0].value) {
                    this.addObject.valid_conditions.days = []
                    this.addObject.valid_conditions.weekdays = []
                } else if (val === this.repeatInterval[1].value) {
                    this.addObject.valid_conditions.days = []
                } else {
                    this.addObject.valid_conditions.weekdays = []
                }
            }
        }
    }
</script>
<style>
.timePickerNoDate .vc-date{
    display: none;
}
</style>
<style scoped>
.day {
  width: 2rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-left: 1.5rem;*/
  margin: 0.4rem 0.9rem;
  color: black;
}
.day.active {
  border-color: var(--primary);
  background-color: var(--primary);
  color: white;
}
</style>
